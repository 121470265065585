import { CutNote } from '..';
import { useTranslation } from 'next-i18next';

export const useFemaleCutNotes = (): CutNote[] => {
  const { t } = useTranslation('cutNote');

  return [
    {
      name: t('common.topSidesBack.label'),
      items: [
        {
          label: t('female.topSidesBack.items.cutLine.label'),
          name: 'cutLine',
          options: [
            {
              label: t('female.topSidesBack.items.cutLine.options.vShape'),
              value: 'vShape',
            },
            {
              label: t('female.topSidesBack.items.cutLine.options.round'),
              value: 'round',
            },
            {
              label: t('female.topSidesBack.items.cutLine.options.straight'),
              value: 'straight',
            },
            {
              label: t('female.topSidesBack.items.cutLine.options.bob'),
              value: 'bob',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.shapeStyle.label'),
          name: 'shapeStyle',
          options: [
            {
              label: t('female.topSidesBack.items.shapeStyle.options.layered'),
              value: 'layered',
            },
            {
              label: t('female.topSidesBack.items.shapeStyle.options.graduated'),
              value: 'graduated',
            },
            {
              label: t('female.topSidesBack.items.shapeStyle.options.oneLength'),
              value: 'oneLength',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.front.label'),
          name: 'front',
          options: [
            {
              label: t('female.topSidesBack.items.front.options.layered'),
              value: 'layered',
            },
            {
              label: t('female.topSidesBack.items.front.options.graduated'),
              value: 'graduated',
            },
            {
              label: t('female.topSidesBack.items.front.options.oneLength'),
              value: 'oneLength',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.back.label'),
          name: 'back',
          options: [
            {
              label: t('female.topSidesBack.items.back.options.layered'),
              value: 'layered',
            },
            {
              label: t('female.topSidesBack.items.back.options.graduated'),
              value: 'graduated',
            },
            {
              label: t('female.topSidesBack.items.back.options.oneLength'),
              value: 'oneLength',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.bangs.label'),
          name: 'bangs',
          options: [
            {
              label: t('female.topSidesBack.items.bangs.options.curtain'),
              value: 'curtain',
            },
            {
              label: t('female.topSidesBack.items.bangs.options.straight'),
              value: 'straight',
            },
            {
              label: t('female.topSidesBack.items.bangs.options.side'),
              value: 'side',
            },
            {
              label: t('female.topSidesBack.items.bangs.options.round'),
              value: 'round',
            },
          ],
        },
      ],
    },
    {
      name: t('common.connection.label'),
      items: [
        {
          label: t('common.connection.label'),
          name: 'connection',
          limit: 2,
          options: [
            {
              label: t('common.connection.options.undercut'),
              value: 'undercut',
            },
            {
              label: t('common.connection.options.blended'),
              value: 'blended',
            },
            {
              label: t('common.connection.options.disconnected'),
              value: 'disconnected',
            },
          ],
        },
      ],
    },
    {
      name: t('common.details.label'),
      items: [
        {
          label: t('common.details.items.thinnedOut.label'),
          name: 'thinnedOut',
          limit: 3,
          options: [
            {
              label: t('common.details.items.thinnedOut.options.front'),
              value: 'front',
            },
            {
              label: t('common.details.items.thinnedOut.options.back'),
              value: 'back',
            },
            {
              label: t('common.details.items.thinnedOut.options.top'),
              value: 'top',
            },
            {
              label: t('common.details.items.thinnedOut.options.ends'),
              value: 'ends',
            },
          ],
        },
        {
          label: t('common.details.items.hairAroundEars.label'),
          name: 'hairAroundEars',
          options: [
            {
              label: t('common.details.items.hairAroundEars.options.freeEars'),
              value: 'freeEars',
            },
            {
              label: t('common.details.items.hairAroundEars.options.overEars'),
              value: 'overEars',
            },
          ],
        },
        {
          label: t('common.details.items.neckLine.label'),
          name: 'neckLine',
          options: [
            {
              label: t('common.details.items.neckLine.options.line'),
              value: 'line',
            },
            {
              label: t('common.details.items.neckLine.options.natural'),
              value: 'natural',
            },
            {
              label: t('common.details.items.neckLine.options.blend'),
              value: 'blend',
            },
            {
              label: t('common.details.items.neckLine.options.feather'),
              value: 'feather',
            },
          ],
        },
        {
          label: t('common.details.items.sideBurns.label'),
          name: 'sideBurns',
          limit: 1,
          options: [
            {
              label: t('common.details.items.sideBurns.options.beginning'),
              value: 'beginning',
            },
            {
              label: t('common.details.items.sideBurns.options.mid'),
              value: 'mid',
            },
            {
              label: t('common.details.items.sideBurns.options.blended'),
              value: 'blended',
            },
            {
              label: t('common.details.items.sideBurns.options.long'),
              value: 'long',
            },
          ],
        },
      ],
    },
    {
      name: t('common.frequency.label'),
      items: [
        {
          label: t('common.frequency.suggestedWeekFrequency.label'),
          name: 'suggestedWeekFrequency',
          options: [
            {
              label: `6 ${t('common.weeks')}`,
              value: '6',
            },
            {
              label: `8 ${t('common.weeks')}`,
              value: '8',
            },
            {
              label: `10 ${t('common.weeks')}`,
              value: '10',
            }
          ],
        },
      ],
    },
  ];
};