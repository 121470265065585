import { sanityClient } from 'lib/sanity.server';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite'

const sanityFetcher = async (query: string, locale: string, refreshInterval: number | undefined = undefined) => {
  return sanityClient.fetch(query, { locale }, refreshInterval ? {
    next: { revalidate: (refreshInterval - 1) / 1000 },
  }: undefined);
};

export const useSanity = <Data>(query: string, locale = 'nb', refreshInterval: number | undefined = undefined) => {
  return useSWR<Data>([query, locale, refreshInterval], sanityFetcher, {
    revalidateOnFocus: false,
    refreshInterval,
  });
};

export const useSanityPaged = <Data>(query: string, locale = 'nb', pageSize = 5, refreshInterval: number | undefined = undefined) => {
  const getKey = (pageIndex: number, previousPageData: Array<any> | null) => {
    if (previousPageData && !previousPageData.length) return null;

    const pageParams = {
      pageStart: pageIndex * pageSize,
      pageEnd: (pageIndex + 1) * pageSize - 1,
    }

    const pagedQuery = Object.entries(pageParams).reduce((acc, [key, value]) => {
      return acc.replace(`\$${key}`, value.toString());
    }, query);

    return [pagedQuery, locale, refreshInterval];
  }
  
  return useSWRInfinite<Data>(getKey, sanityFetcher, {
    revalidateOnFocus: false,
    refreshInterval,
  });
};
