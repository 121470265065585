import { useSanityPaged } from 'hooks';
import { Newsletter } from 'models/newsletter.model';

export const useHairdresserNews = (locale = 'en', pageSize = 5) => {
  const { data, error, isLoading, size, setSize  } = useSanityPaged<Newsletter[]>(`
    *[_type == $locale+"_hairdresser_news_test"]{ ..., attachments[]{
      "pdf": asset->url,
      "description": description
    } } | order(date desc)[$pageStart..$pageEnd]
  `, locale, pageSize, 1000 * 60 * 30); // 30 minutes

  return {
    hairdresserNews: data?.flat(),
    isLoading: isLoading || (!error && !data),
    error: error,
    size,
    setSize 
  };
};
